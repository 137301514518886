/* Global Body Styling */
@font-face {
  font-family: 'Helvetica Neue';
  src: url('/public/fonts/HelveticaNeueMedium.woff') format('woff2'),
       url('/public/fonts/HelveticaNeueMedium.woff2') format('woff');
  font-weight: 300; /* Adjust this if needed */
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Neue Light';
  src: url('/public/fonts/HelveticaNeueLight.woff2') format('woff2'),
       url('/public//fonts/HelveticaNeueLight.woff') format('woff');
  font-weight: 300; /* Light weight */
  font-style: normal;
}

.bold-text {
  font-family: 'Helvetica Neue';
}


body {
  margin: 0;
  padding: 0;
  font-family: 'Helvetica Neue Light', 'Roboto Light', 'sans-serif';
  background-color: white;
  color: black;
  line-height: 1.5;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

/* General container styling */
.container {
  max-width: 1500px;
  margin: 0 auto;
  padding: 0 20px;
  box-sizing: border-box;
}

/* General Navbar Styling */
.navbar {
  background-color: #fff;
  padding: 10px 20px;
  position: sticky;
  top: 0;
  z-index: 1000; /* Ensure it stays on top */
}

.navbar .container {
  display: flex;
  justify-content: space-between; /* Space between logo and menu */
  align-items: center;
}

.logo-container {
  display: flex;
  align-items: center;
}

.tangentic-icon {
  width: 30px;
  margin-right: 10px;
}

.logo {
  width: 150px;
}

/* Navbar Menu Styling */
.navbar-menu {
  display: flex;
  gap: 20px;
  align-items: center; /* Ensure vertical alignment */
}

.navbar-link {
  text-decoration: none;
  color: #4c4c4c;
  font-size: 16px;
  font-weight: 500;
  font-family: 'Helvetica Neue', 'Roboto', 'sans-serif';
  transition: color 0.3s ease; /* Smooth color transition */
}

.navbar-link:hover {
  color: #66aaff;
}

/* Active link styling */
.navbar-link.active {
  font-weight: bold;
  color: #333;
}

/* Book Call Button (Submenu) */
.book-call-button {
  padding: 8px 16px;
  background-color: #007bff; /* Primary button color */
  color: white;
  border: none;
  border-radius: 20px;
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;
  text-align: center;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.book-call-button:hover {
  background-color: #0056b3;
  transform: translateY(-2px);
}

/* Hamburger Menu Styling for Mobile */
.hamburger {
  display: none;
  font-size: 24px;
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px;
  color: #4c4c4c;
}

/* Responsive Menu Styling */
@media (max-width: 768px) {
  .navbar-menu {
    display: none;
    position: absolute;
    top: 60px;
    right: 10px;
    background-color: white;
    flex-direction: column;
    gap: 15px;
    padding: 15px;
    border: 1px solid #ddd;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }

  .navbar-menu.open {
    display: flex; /* Display the menu when open */
  }

  .hamburger {
    display: block;
  }

  /* Button inside hamburger menu */
  .book-call-button {
    margin-top: 10px; /* Spacing between button and menu items */
    padding: 8px 16px;
    text-align: center;
    font-size: 14px;
    width: 100%; /* Full width inside the menu */
  }
}

/* Main Content Styling */
.main-content {
  flex-grow: 1;
  padding: 20px 10px; /* Added 10px padding for main content */
  text-align: center;
  box-sizing: border-box;
}

.description-section {
  width: 100%;
  font-size: large;
  max-width: 800px;
  margin: 0 auto 0;
  padding: 0px 0px 50px 0px ; /* Added 10px padding for section content */
  text-align: left;
  font-size: 2rem;  
  line-height: 1.5;
}
/* Media query for mobile devices */
@media only screen and (max-width: 600px) {
  .description-section {
    font-size: 1.5rem; /* Adjust the value as needed */
  }
}
.contact-section {
  width: 100%;
  max-width: 800px;
  margin: 0 auto 0;
  padding: 10px; /* Added 10px padding for section content */
  text-align: left;
}

h1 {
  font-size: 2.5rem;
  font-family: 'Helvetica Neue Bold', 'Roboto', sans-serif;
  font-weight:bold;
}

h2, h3 {
  font-size: 2rem;
  margin-bottom: 10px;
  font-weight: 300;
}

p {
  font-size: 1.2rem;
  margin-bottom: 20px;
  font-weight: 400;
}

/* Link Styling */
a {
  color: black;
  text-decoration: underline;
}

a:hover {
  text-decoration: none;
}

/* Mobile responsiveness for main content */
@media (max-width: 768px) {
  .logo {
    height: 50px;
  }

  h2, h3 {
    font-size: 1.5rem;
  }

  p {
    font-size: 1rem;
  }

  .main-content {
    padding: 10px;
  }

  .mission-section, .contact-section {
    max-width: 100%;
    padding: 10px;
  }
}

/* Remove unnecessary large padding and styles for the button */
.book-call-button {
  background-color: #66aaff; /* Use the updated primary color */
  color: white;
  padding: 6px 12px; /* Match the smaller size for navbar */
  border: none;
  border-radius: 15px; /* Smaller, rounded corners */
  font-size: 12px; /* Match the navbar size */
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  text-decoration: none;
  text-align: center;
  display: inline-block;
  font-family: 'Helvetica Neue', 'Roboto', sans-serif;
  font-weight: 500;
}

.book-call-button:hover {
  background-color: #0056b3; /* Match the hover style for consistency */
  transform: translateY(-2px);
}

/* Optional: Retain the container styling for centered display elsewhere */
.book-call-container {
  text-align: center;
  margin-top: 50px; /* You can adjust or remove this if unused */
}


/* Product Grid Styling */
.product-grid {
  display: flex;
  gap: 20px;
  justify-content: space-between;
}

.product {
  flex: 1;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-sizing: border-box;
  cursor: pointer;
  transition: border-color 0.3s ease;
}

.product-details {
  width: 100%;
  max-width: 800px;
  margin: auto;
  text-align: left;
}

/* Hover and Selected States */
.product:hover {
  border-color: #66aaff;
}

.product.selected {
  border-color: #66aaff;
}

/* Mobile responsiveness for product grid */
@media (max-width: 768px) {
  .product-grid {
    flex-direction: column; /* Stack products vertically */
  }

  .product {
    width: 100%; /* Full width for each product */
    margin-bottom: 5px; /* Space between stacked products */
  }
}




/* Research Page Styling */
.research-page {
  font-family: 'Helvetica Neue Regular', 'Roboto', sans-serif;
  padding: 20px;
  text-align: center;
}

.research-list {
  padding: 0;
  margin: 0 auto;
  max-width: 800px;
  list-style-type: none; /* Remove bullets */
}

/* Research card styles */
.research-card {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  margin: 20px 0;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s, box-shadow 0.2s;
}

.research-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

/* Title and PDF indicator */
.paper-title {
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  color: #333;
  text-decoration: none;
  margin-bottom: 10px;
}

.paper-title:hover {
  color: #66aaff;
}

.pdf-indicator {
  font-size: 20px;
  margin-right: 8px;
  color: #66aaff; /* Match hover color */
}

.authors {
  font-size: 16px;
  font-style: italic;
  color: #444;
  margin: 5px 0;
  text-align: left;
}

.abstract {
  font-size: 14px;
  color: #666;
  margin: 10px 0;
}

.read-more {
  background: none;
  border: none;
  color: #66aaff;
  cursor: pointer;
  font-size: 14px;
  text-decoration: underline;
  margin-left: 5px;
}

.read-more:hover {
  text-decoration: none;
}




/* Page container for layout control */
.page-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Ensure it fills the full height of the viewport */
}



/* Footer styling */
.footer {
  padding: 50px 30px; /* Adjusted padding */
  font-family: 'Helvetica Neue', 'Roboto Light';
  text-align: left;
  font-size: 10px;
  color: black;
  width: 100%;
  margin-top: auto; /* Pushes footer to the bottom when space is available */
}

.footer .container {
  max-width: 1500px;
  margin: 0 auto;
  display: flex;
  align-items: center;
}

.linkedin-icon {
  width: 16px;
  height: 16px;
  vertical-align: middle;
}
